import React, { memo } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { ColorPaletteItem } from '@lgg/isomorphic';
import { Icon } from 'src/components/general/icon';

const TabMessageContainer = styled.div<{ borderColor: ColorPaletteItem }>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: solid 1px ${({ borderColor, theme }) => theme.colors[borderColor]};
  box-shadow: 0 5px 10px 0 rgba(114, 125, 142, 0.06);
  display: flex;
  margin: 15px 20px 10px;
  padding: 11px 15px;

  ${up('md')} {
    margin: 15px 20px 10px;
    padding: 16px 16px 14px;
  }
`;

const TabMessageTitle = styled.p`
  color: ${({ theme }) => theme.colors.smalt};
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 16px;
  margin-bottom: 5px;
  text-align: left;

  ${up('md')} {
    margin-bottom: 3px;
  }
`;

const TabMessageBody = styled.div`
  color: ${({ theme }) => theme.colors.storm};
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-height: 14px;
  margin: 0;
  text-align: left;

  ${up('md')} {
    line-height: 16px;
  }
`;

const TabMessageIcon = styled(Icon)<{ color: ColorPaletteItem }>`
  box-shadow: 0 15px 34px 0 rgba(20, 133, 158, 0.15);
  height: 18px;
  margin-right: 10px;
  width: 18px;

  svg path {
    fill: ${({ color, theme }) => theme.colors[color]};
  }
`;

type ContactInteractionsReplyTabMessageProps = {
  title: string;
  body: React.ReactNode;
  lggId: string;
  color?: ColorPaletteItem;
};

export const ContactInteractionsReplyTabInfoMessage =
  memo<ContactInteractionsReplyTabMessageProps>(
    ({ title, body, lggId, color = 'topaz' }) => {
      const borderColor = `${color}60` as ColorPaletteItem;

      return (
        <TabMessageContainer borderColor={borderColor} data-lgg-id={lggId}>
          <TabMessageIcon color={color} type="infoAlertIcon" />
          <div>
            <TabMessageTitle>{title}</TabMessageTitle>
            <TabMessageBody>{body}</TabMessageBody>
          </div>
        </TabMessageContainer>
      );
    },
  );
