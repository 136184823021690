import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import c from 'classnames';
import {
  $getRoot,
  CLEAR_EDITOR_COMMAND,
  LexicalEditor as LexicalEditorType,
  $nodesOfType,
} from 'lexical';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import { match, P } from 'ts-pattern';
import { ColorPaletteItem } from '@lgg/isomorphic';
import {
  Query,
  QueryContactInteractionChannelAvailabilityForContactArgs,
  ContactInteractionChannelAvailabilityForContact,
  ContactInteractionChannelSlug,
  ConversationItemsWhereInput,
  Mutation,
  MutationConversationAddInternalNoteArgs,
  MutationConversationSendSmsArgs,
  MutationConversationSendFacebookMessageArgs,
  MutationConversationSendWhatsappMessageArgs,
  ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability,
  ContactInteractionChannelResourceUnion,
  ConversationItemUnion,
  ContactPhoneType,
  QueryContactArgs,
  PhoneNumber,
  Contact,
  MutationConversationSendInstagramMessageArgs,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { DrawerSelectableOption } from 'src/components/general/drawer/bottom/selectable-options-bottom-drawer';
import { useShowNotification } from 'src/components/general/feedback/hooks/use-show-notification';
import { FlexColumn } from 'src/components/layout/flex-column';
import { BlockedContactBanner } from 'src/components/pages/contacts/components/contact-block';
import {
  SmsLimitReachedBanner,
  SmsOptOutBanner,
  SmsResourceNotLinkedBanner,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/sms/sms';
import { WhatsappMessageClosedWindowContent } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp';
import {
  AddWhatsappTemplateOption,
  SendTemplateOptionIcon,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/channels/whatsapp/whatsapp-template/add-whatsapp-template-option';
import { InputUploadFile } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interaction-input-area-attachment-options';
import { ContactInteractionsReplyTabInfoMessage } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-reply-tab-messages';
import {
  ADD_INTERNAL_NOTE,
  CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT,
  CONVERSATION_SEND_FACEBOOK_MESSAGE,
  CONVERSATION_SEND_INSTAGRAM_MESSAGE,
  CONVERSATION_SEND_SMS,
  CONVERSATION_SEND_WHATSAPP_MESSAGE,
  GET_CONTACT_CONTACT_INFORMATION,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/graphql-operations';
import { MessageTypeSelector } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/message-type-selector';
import { ReplyChannelSelector } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/reply-channel-selector';
import { ReplyWindowBar } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/reply-window-bar';
import { ResourceSelectorBar } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/resource-selector-bar';
import { RightRailComplementElement } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/right-rail-complement-element';
import {
  InputAreaContainer,
  InputAreaContent,
  getChannelErrorMessage,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/shared';
import { AddContactInteractionModal } from 'src/components/pages/conversations/components/contact-interactions/dev-tools/add-contact-interaction-modal';
import { LexicalEditor } from 'src/components/pages/conversations/components/general/lexical-editor/lexical-editor';
import { UserMentionNode } from 'src/components/pages/conversations/components/general/lexical-editor/nodes/user-mention-node';
import { openRemoteModalWrapper } from 'src/components/pages/legacy/components/open-legacy-remote-modal-link';
import { SafeAreaInsetsContext } from 'src/components/providers/safe-area-insets-provider';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useBreakpoint } from 'src/hooks/use-breakpoint';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useGetVisualViewport } from 'src/hooks/use-get-visual-viewport';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useUrls } from 'src/hooks/use-urls';
import { publishEvent } from 'src/utils/events/pub-sub';

const InternalNoteInfo = styled.p`
  font-family: ${({ theme }) => theme.font.regular};
  font-size: 11px;
  line-height: 13px;
  color: ${({ theme }) => theme.colors.flint};
  margin: 0;
  user-select: none;
`;

const EditContactDetailDescription = styled.span`
  color: ${({ theme }) => theme.colors.secondaryTopaz};
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.medium};
  font-size: 12px;
  line-height: 14px;
  margin-top: 10px;
`;

export const InternalNotePrivacyMessage = () => {
  const { t } = useTranslation(['conversations', 'smsChannel']);

  return (
    <InternalNoteInfo>{`(${t(
      'conversations:messageInput.tabs.internalNote.info',
    )})`}</InternalNoteInfo>
  );
};

export enum ContactInteractionInputAreaTabKey {
  Reply = 'Reply',
  InternalNote = 'InternalNote',
}

export type ContactPhoneOption = PhoneNumber & { type: ContactPhoneType };

type MessageValues = {
  message: string;
  rawMessage: string;
};

type ContactInteractionsInputAreaProps = {
  handleFetchCreatedItem: (where: ConversationItemsWhereInput) => void;
  conversationId: string;
  contact: Contact;
  setIsRendered: ValueChanged<boolean>;
  selectedInteraction?: ConversationItemUnion;
};

export const ContactInteractionsInputArea = memo<ContactInteractionsInputAreaProps>(
  ({
    handleFetchCreatedItem,
    setIsRendered,
    conversationId,
    contact,
    selectedInteraction,
  }) => {
    const { id: contactId } = contact;
    const breakpointUpMd = useBreakpoint(up('md'));
    const [hasFocus, setHasFocus] = useState(false);
    const [selectedTabKey, setSelectedTabKey] =
      useState<ContactInteractionInputAreaTabKey>(
        ContactInteractionInputAreaTabKey.Reply,
      );
    const [isFullHeight, setFullHeightReply] = useState(false);
    const [messageValues, setMessageValues] = useState<MessageValues>({
      message: '',
      rawMessage: '',
    });

    const [channelOptions, setChannelOptions] = useState<
      ContactInteractionChannelAvailabilityForContact[] | undefined
    >(undefined);
    const [selectedChannelSlug, setSelectedChannelSlug] =
      useState<ContactInteractionChannelSlug | null>(null);
    const [channelAvailabilityForContact, setChannelAvailabilityForContact] =
      useState<ContactInteractionChannelAvailabilityForContact | null>(null);
    const [selectedResource, setSelectedResource] =
      useState<ContactInteractionChannelResourceUnion | null>(null);
    const [selectedContactAddress, setSelectedContactAddress] =
      useState<ContactPhoneOption | null>(null);
    const [contactPhoneNumbers, setContactPhoneNumbers] = useState<ContactPhoneOption[]>(
      [],
    );
    const [selectedResourceAvailability, setSelectedResourceAvailability] = useState<
      | ContactInteractionChannelAvailabilityForContactResourceToContactAddressAvailability
      | undefined
    >(undefined);
    const [attachmentList, setAttachmentList] = useState<InputUploadFile[]>([]);
    const inputRef = useRef<TextAreaRef>(null);
    const showNotification = useShowNotification();
    const { t } = useTranslation(['errors', 'conversations', 'common', 'smsChannel']);
    const handleGraphQLError = useHandleGraphQLError();
    const { getFeatureFlag } = useAuthorization();
    const isAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-265_como-usuario-quiero-poder-adjuntar-archivos-de-modo-que-pueda-compartir-con-mi-cliente-detalles-rele',
    );
    const isMentionsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-933_agregar-feature-flag-para-los-mention-en-internal-notes-y-los-attachments',
    );
    const isWhatsappTemplatesFeatureEnabled = getFeatureFlag(
      'temp_lggdev-1353_mostrar-un-mensaje-de-ventana-de-contacto-cerrada-al-pasar-las-24-horas-y-seleccionar-el-canal-de-wh',
    );
    const isSmsAndFacebookMessengerAttachmentsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-1887_deshabilitar-attachments-para-sms-y-facebook-messenger',
    );
    const isAiToolsFeatureEnabled = getFeatureFlag('AI');
    const { visualViewportHeight } = useGetVisualViewport();
    const insets = useContext(SafeAreaInsetsContext);
    const { getLegacyContactEditUrl } = useUrls();
    const { parseISO, isFuture } = useDateHelpers();

    useEffect(() => {
      if (hasFocus && inputRef.current) {
        inputRef.current.focus({
          cursor: 'end',
        });
      }
    }, [hasFocus, isFullHeight]);

    useEffect(() => {
      if (conversationId) {
        setMessageValues({
          message: '',
          rawMessage: '',
        });
      }
    }, [conversationId]);

    const handleReplyError = (error: ApolloError) => {
      const [firstError] = error.graphQLErrors;

      if (firstError?.extensions?.code.startsWith('CHANNELS:')) {
        showNotification({
          type: 'error',
          title: t('errors:notification.title'),
          message: getChannelErrorMessage(firstError.extensions.code),
        });
      } else {
        handleGraphQLError(error);
      }
    };

    useQuery<Pick<Query, 'contact'>, QueryContactArgs>(GET_CONTACT_CONTACT_INFORMATION, {
      variables: {
        id: contactId,
      },
      onCompleted: ({ contact }) => {
        const { primaryPhone, secondaryPhone } = contact;
        const contactPhoneNumbers: ContactPhoneOption[] = [];

        if (primaryPhone) {
          contactPhoneNumbers.push({
            ...primaryPhone,
            type: 'PRIMARY',
          });
        }

        if (secondaryPhone) {
          contactPhoneNumbers.push({
            ...secondaryPhone,
            type: 'SECONDARY',
          });
        }

        setContactPhoneNumbers(contactPhoneNumbers);
      },
    });

    const [sendSms, { loading: isSendingSms }] = useMutation<
      Pick<Mutation, 'conversationSendSms'>,
      MutationConversationSendSmsArgs
    >(CONVERSATION_SEND_SMS, {
      onError: handleReplyError,
    });

    const [sendFacebookMessage, { loading: isSendingFacebookMessage }] = useMutation<
      Pick<Mutation, 'conversationSendFacebookMessage'>,
      MutationConversationSendFacebookMessageArgs
    >(CONVERSATION_SEND_FACEBOOK_MESSAGE, {
      onError: handleReplyError,
    });

    const [sendInstagramMessage, { loading: isSendingInstagramMessage }] = useMutation<
      Pick<Mutation, 'conversationSendInstagramMessage'>,
      MutationConversationSendInstagramMessageArgs
    >(CONVERSATION_SEND_INSTAGRAM_MESSAGE, {
      onError: handleReplyError,
    });

    const [sendWhatsappMessage, { loading: isSendingWhatsappMessage }] = useMutation<
      Pick<Mutation, 'conversationSendWhatsappMessage'>,
      MutationConversationSendWhatsappMessageArgs
    >(CONVERSATION_SEND_WHATSAPP_MESSAGE, {
      onError: handleReplyError,
    });

    const [addInternalNote, { loading: isSendingInternalNote }] = useMutation<
      Pick<Mutation, 'conversationAddInternalNote'>,
      Partial<MutationConversationAddInternalNoteArgs>
    >(ADD_INTERNAL_NOTE, {
      onError: (error) => handleGraphQLError(error),
    });

    const isSendingReplyOrNote =
      isSendingSms ||
      isSendingFacebookMessage ||
      isSendingWhatsappMessage ||
      isSendingInstagramMessage ||
      isSendingInternalNote;

    const { loading: fetchingReplyChannels } = useQuery<
      Pick<Query, 'contactInteractionChannelAvailabilityForContact'>,
      QueryContactInteractionChannelAvailabilityForContactArgs
    >(CONTACT_INTERACTION_CHANNEL_AVAILABILITY_FOR_CONTACT, {
      variables: {
        contactId: contactId,
      },
      fetchPolicy: 'network-only',
      skip: contactId === null,
      onCompleted: ({ contactInteractionChannelAvailabilityForContact: data }) => {
        const sortedChannels = [...data].sort(
          (interactionChannel, nextInteractionChannel) =>
            Number(nextInteractionChannel.isAvailable) -
            Number(interactionChannel.isAvailable),
        );

        const defaultSelectedChannel = [...data]
          .filter((channel) => channel.isAvailable)
          .sort(
            (interactionChannel, nextInteractionChannel) =>
              new Date(nextInteractionChannel.lastInteractionDate).getTime() -
              new Date(interactionChannel.lastInteractionDate).getTime(),
          )?.[0];

        setSelectedChannelSlug(defaultSelectedChannel?.channel.slug ?? null);
        setChannelOptions(sortedChannels);
      },
      onError: handleGraphQLError,
    });

    useEffect(() => {
      // The channel availability gets updated when the selected channel changes.
      if (!selectedChannelSlug || !channelOptions) {
        return;
      }

      const selectedChannelOption = channelOptions.find(
        (channel) => channel.channel.slug === selectedChannelSlug,
      );

      if (!selectedChannelOption) {
        return;
      }

      const defaultSelectedResource = match(selectedChannelSlug)
        .with(P.union('SMS', 'WHATSAPP', 'INSTAGRAM'), () => {
          const { resources, lastUsedResourceId } = selectedChannelOption;

          return (
            resources.find(({ id }) => id === lastUsedResourceId) ?? resources[0] ?? null
          );
        })
        .otherwise(() => null);

      const defaultSelectedContactAddress: ContactPhoneOption | null = match(
        selectedChannelSlug,
      )
        .with(P.union('SMS', 'WHATSAPP'), () => {
          const { lastInteractionContactAddress } = selectedChannelOption;

          return (
            contactPhoneNumbers.find(
              (phone) => phone.e164 === lastInteractionContactAddress,
            ) ??
            contactPhoneNumbers[0] ??
            null
          );
        })
        .otherwise(() => null);

      setSelectedResource(defaultSelectedResource);
      setChannelAvailabilityForContact(selectedChannelOption);
      setSelectedContactAddress(defaultSelectedContactAddress);
    }, [channelOptions, contactPhoneNumbers, selectedChannelSlug]);

    useEffect(() => {
      // The resource availability gets updated when the selected resource or contact address changes.
      match(selectedChannelSlug)
        .with(P.union('SMS', 'WHATSAPP'), () => {
          if (channelAvailabilityForContact) {
            setSelectedResourceAvailability(
              channelAvailabilityForContact.resourceToContactAddressAvailability?.find(
                (resource) =>
                  resource.resourceId === selectedResource?.id &&
                  resource.contactAddress === selectedContactAddress?.e164,
              ),
            );
          }
        })
        .with('INSTAGRAM', () => {
          if (channelAvailabilityForContact) {
            setSelectedResourceAvailability(
              channelAvailabilityForContact.resourceToContactAddressAvailability?.find(
                (resource) => resource.resourceId === selectedResource?.id,
              ),
            );
          }
        })
        .otherwise(() => null);
    }, [
      channelAvailabilityForContact,
      selectedChannelSlug,
      selectedContactAddress,
      selectedResource,
      selectedResource?.id,
    ]);

    useEffect(() => {
      if (!fetchingReplyChannels) {
        setIsRendered(true);
      }
    }, [fetchingReplyChannels, setIsRendered]);

    const hasValidAttachments =
      attachmentList.length > 0 &&
      attachmentList.some((attachment) =>
        ['UPLOADING', 'UPLOADED'].includes(attachment.status),
      );
    const isUploadingFiles = attachmentList.some(
      (attachment) => attachment.status !== 'ERROR' && attachment.url === null,
    );
    const isInputActive =
      !!conversationId && (!!messageValues.message || hasValidAttachments);

    const isWhatsAppChannelSelected = selectedChannelSlug === 'WHATSAPP';
    const isSelectedChannelContactWindowOpen = isFuture(
      parseISO(selectedResourceAvailability?.expiresAt),
    );

    const selectedChannelWindowAvailabilityMessage = useMemo(() => {
      if (!selectedChannelSlug || !isSelectedChannelContactWindowOpen) {
        return null;
      }

      return (
        <ReplyWindowBar
          expirationDate={selectedResourceAvailability?.expiresAt}
          type={selectedChannelSlug}
        />
      );
    }, [
      isSelectedChannelContactWindowOpen,
      selectedChannelSlug,
      selectedResourceAvailability?.expiresAt,
    ]);

    const clearMessageInputStates = useCallback(() => {
      setMessageValues({
        message: '',
        rawMessage: '',
      });
      setAttachmentList([]);

      if (!breakpointUpMd) {
        setHasFocus(false);
      }
    }, [breakpointUpMd]);

    const selectedResourcePhoneNumber =
      selectedResource?.__typename === 'ContactInteractionChannelPhoneResource'
        ? selectedResource.phoneNumber.national
        : undefined;

    const handleSendMessage = useCallback(
      async (editor: LexicalEditorType) => {
        const handleSendReplyCompleted = async (
          interactionId: string,
          onCompleted: VoidFunction,
        ) => {
          if (conversationId) {
            onCompleted();

            await handleFetchCreatedItem({
              contactInteractionId: {
                eq: Number(interactionId),
              },
            });

            publishEvent('CONVERSATION_UPDATED', { id: conversationId });
          }
        };

        const handleSendReply = async (onCompleted: VoidFunction) => {
          if (isUploadingFiles) {
            showNotification({
              title: t(
                'conversations:messageInput.options.attachments.errorMessages.fileStillUploading',
              ),
              type: 'warning',
            });

            return;
          }

          if (isInputActive && conversationId) {
            let commonVariables: Pick<
              MutationConversationSendSmsArgs,
              'conversationId' | 'message' | 'attachments'
            > = {
              conversationId,
              message: messageValues.message,
              attachments: [],
            };

            const attachments = attachmentList.map(({ url }) => ({
              s3UploadedUrl: url ?? '',
            }));

            if (isAttachmentsFeatureEnabled && attachments.length > 0) {
              commonVariables = {
                ...commonVariables,
                attachments,
              };
            }

            switch (selectedChannelSlug) {
              case 'SMS': {
                await sendSms({
                  variables: {
                    ...commonVariables,
                    contactPhoneType: selectedContactAddress?.type ?? null,
                    fromResourceId: selectedResource?.id
                      ? Number(selectedResource.id)
                      : null,
                  },
                  onCompleted: async ({ conversationSendSms: data }) => {
                    if (!data.interaction?.id) {
                      return;
                    }

                    await handleSendReplyCompleted(data.interaction.id, onCompleted);
                  },
                });
                break;
              }
              case 'FACEBOOK_MESSENGER': {
                await sendFacebookMessage({
                  variables: commonVariables,
                  onCompleted: async ({ conversationSendFacebookMessage: data }) => {
                    if (!data.interaction?.id) {
                      return;
                    }

                    await handleSendReplyCompleted(data.interaction.id, onCompleted);
                  },
                });
                break;
              }
              case 'WHATSAPP': {
                if (!selectedResourceAvailability) {
                  return;
                }

                await sendWhatsappMessage({
                  variables: {
                    ...commonVariables,
                    contactPhoneNumber:
                      selectedContactAddress?.e164 ??
                      selectedResourceAvailability.contactAddress,
                    resourceId: selectedResourceAvailability.resourceId,
                  },
                  onCompleted: async ({ conversationSendWhatsappMessage: data }) => {
                    if (!data.interaction?.id) {
                      return;
                    }

                    await handleSendReplyCompleted(data.interaction.id, onCompleted);
                  },
                });
                break;
              }
              case 'INSTAGRAM': {
                if (!selectedResourceAvailability) {
                  return;
                }

                await sendInstagramMessage({
                  variables: {
                    ...commonVariables,
                    resourceId: selectedResourceAvailability.resourceId,
                  },
                  onCompleted: async ({ conversationSendInstagramMessage: data }) => {
                    if (!data.interaction?.id) {
                      return;
                    }

                    await handleSendReplyCompleted(data.interaction.id, onCompleted);
                  },
                });
                break;
              }
            }
          }
        };

        const handleAddInternalNote = async (
          onCompleted: VoidFunction,
          userMentionsIds: number[],
        ) => {
          if (isInputActive && conversationId) {
            await addInternalNote({
              variables: {
                conversationId,
                noteText: messageValues.message,
                rawNoteText: messageValues.rawMessage,
                mentions: userMentionsIds,
              },
              onCompleted: async ({ conversationAddInternalNote }) => {
                const { note } = conversationAddInternalNote;
                onCompleted();

                await handleFetchCreatedItem({
                  conversationNoteId: {
                    eq: note.id,
                  },
                });
              },
            });
          }
        };

        if (isSendingReplyOrNote) return;

        const onCompleted = () => {
          clearMessageInputStates();
          editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
        };

        switch (selectedTabKey) {
          case ContactInteractionInputAreaTabKey.Reply: {
            await handleSendReply(onCompleted);
            break;
          }

          case ContactInteractionInputAreaTabKey.InternalNote: {
            let userMentionsIds: number[] = [];

            editor.update(() => {
              const userMentionNodes = $nodesOfType(UserMentionNode);

              userMentionsIds = userMentionNodes.map((mentionNode) =>
                Number(mentionNode.userId),
              );
            });

            await handleAddInternalNote(onCompleted, userMentionsIds);
            break;
          }
        }

        if (isFullHeight) {
          setFullHeightReply(false);
        }
      },
      [
        isSendingReplyOrNote,
        selectedTabKey,
        isFullHeight,
        conversationId,
        handleFetchCreatedItem,
        isUploadingFiles,
        isInputActive,
        showNotification,
        t,
        messageValues.message,
        messageValues.rawMessage,
        attachmentList,
        isAttachmentsFeatureEnabled,
        selectedChannelSlug,
        sendSms,
        selectedContactAddress?.type,
        selectedContactAddress?.e164,
        selectedResource?.id,
        sendFacebookMessage,
        selectedResourceAvailability,
        sendWhatsappMessage,
        addInternalNote,
        sendInstagramMessage,
        clearMessageInputStates,
      ],
    );

    const resolveLeftRailExtraContent = (
      channelOptions: ContactInteractionChannelAvailabilityForContact[],
    ) => {
      switch (selectedTabKey) {
        case ContactInteractionInputAreaTabKey.InternalNote: {
          if (!breakpointUpMd) {
            return <InternalNotePrivacyMessage />;
          }

          return null;
        }
        case ContactInteractionInputAreaTabKey.Reply: {
          const showWhatsappTemplateOption =
            isWhatsappTemplatesFeatureEnabled &&
            isWhatsAppChannelSelected &&
            selectedResource;

          return (
            <>
              {showWhatsappTemplateOption && selectedContactAddress && (
                <AddWhatsappTemplateOption
                  handleFetchCreatedItem={handleFetchCreatedItem}
                  resourceData={{
                    resourceId: selectedResource.id,
                    phoneNumber: selectedResourcePhoneNumber,
                  }}
                  contactPhoneNumber={selectedContactAddress.e164}
                  conversationId={conversationId}
                  renderTrigger={({ visible, showModal }) => (
                    <SendTemplateOptionIcon visible={visible} showModal={showModal} />
                  )}
                />
              )}
              {getFeatureFlag('testing_toolkit') && (
                <AddContactInteractionModal
                  channelOptions={channelOptions}
                  conversationId={conversationId}
                />
              )}
            </>
          );
        }
        default:
          return null;
      }
    };

    const getBackgroundColorBySelectedTabKey = (
      selectedTabKey: ContactInteractionInputAreaTabKey,
    ): ColorPaletteItem => {
      switch (selectedTabKey) {
        case ContactInteractionInputAreaTabKey.InternalNote: {
          return 'secondaryGold10';
        }
        default:
          return 'white';
      }
    };

    const getPlaceholderTextByTabKey = (key: ContactInteractionInputAreaTabKey) => {
      switch (key) {
        case ContactInteractionInputAreaTabKey.InternalNote: {
          return t('conversations:messageInput.tabs.internalNote.inputPlaceholder');
        }
        case ContactInteractionInputAreaTabKey.Reply: {
          return t('conversations:messageInput.tabs.reply.inputPlaceholder');
        }
      }
    };

    const isReplyTabSelected = selectedTabKey === ContactInteractionInputAreaTabKey.Reply;
    const isInternalNoteTabSelected =
      selectedTabKey === ContactInteractionInputAreaTabKey.InternalNote;
    const hideContent = fetchingReplyChannels || !channelOptions;
    const hasChannelsAvailability = useMemo(
      () =>
        isReplyTabSelected
          ? channelOptions && channelOptions.some((channel) => channel.isAvailable)
          : true,
      [channelOptions, isReplyTabSelected],
    );
    const isSmsResourceNotLinked =
      selectedResource?.__typename === 'ContactInteractionChannelSmsResource' &&
      selectedResource.unavailableReasonCode === 'NO_CAMPAIGN';

    const isSmsResourceLimitReached =
      selectedResource?.__typename === 'ContactInteractionChannelSmsResource' &&
      selectedResource.unavailableReasonCode === 'SMS_LIMIT_REACHED';

    const isSmsResourceOptOut =
      selectedResource?.__typename === 'ContactInteractionChannelSmsResource' &&
      selectedResourceAvailability?.unavailableReasonCode === 'OPT_OUT';

    const hideBottomArea = hideContent || !hasChannelsAvailability;

    const isInputEnabled = useMemo(() => {
      if (isReplyTabSelected) {
        if (!hasChannelsAvailability) {
          return false;
        }

        return match(selectedChannelSlug)
          .with('WHATSAPP', () => {
            return (
              selectedResource !== null &&
              isFuture(parseISO(selectedResourceAvailability?.expiresAt))
            );
          })
          .with('INSTAGRAM', () => {
            return isFuture(parseISO(selectedResourceAvailability?.expiresAt));
          })
          .with(
            'SMS',
            () =>
              !isSmsResourceNotLinked &&
              !isSmsResourceLimitReached &&
              !isSmsResourceOptOut,
          )
          .with(null, () => false)
          .otherwise(() => true);
      }

      return true;
    }, [
      isReplyTabSelected,
      hasChannelsAvailability,
      selectedChannelSlug,
      selectedResource,
      isFuture,
      parseISO,
      selectedResourceAvailability?.expiresAt,
      isSmsResourceNotLinked,
      isSmsResourceLimitReached,
      isSmsResourceOptOut,
    ]);

    const mapChannelResourcesToOptions = ({
      resources,
      testIdGenerator,
    }: {
      resources: ContactInteractionChannelResourceUnion[];
      testIdGenerator: (id: string | number) => string;
    }): DrawerSelectableOption[] => {
      return resources.map((resource) => {
        const { displayName, address, id: resourceId } = resource;
        const phoneNumber =
          resource.__typename === 'ContactInteractionChannelPhoneResource'
            ? resource.phoneNumber
            : null;

        return {
          label: phoneNumber?.national ?? address,
          onClick: () => setSelectedResource(resource),
          subTitle: displayName ?? undefined,
          value: resourceId,
          'data-lgg-id': testIdGenerator(resourceId),
        };
      });
    };

    const mapSmsResourcesToGroupedOptions = ({
      resources,
      testIdGenerator,
    }: {
      resources: ContactInteractionChannelResourceUnion[];
      testIdGenerator: (id: string | number) => string;
    }) => {
      return resources.reduce<{
        linked: DrawerSelectableOption[];
        notLinked: DrawerSelectableOption[];
      }>(
        (acc, resource) => {
          const { displayName, address, id: resourceId } = resource;

          if (resource.__typename === 'ContactInteractionChannelSmsResource') {
            const option = {
              label: resource.phoneNumber?.national ?? address,
              onClick: () => setSelectedResource(resource),
              subTitle: displayName ?? undefined,
              value: resourceId,
              'data-lgg-id': testIdGenerator(resourceId),
            };

            if (resource.unavailableReasonCode === 'NO_CAMPAIGN') {
              acc.notLinked.push(option);
            } else {
              acc.linked.push(option);
            }
          }

          return acc;
        },
        { linked: [], notLinked: [] },
      );
    };

    const mapContactAddressesToOptions = useCallback(
      ({
        phoneOptions,
      }: {
        phoneOptions: ContactPhoneOption[];
      }): DrawerSelectableOption[] => {
        return phoneOptions.map((phoneOption) => {
          const { national, type } = phoneOption;
          const optionSubtitle = match(type)
            .with('PRIMARY', () => t('common:primaryPhone'))
            .with('SECONDARY', () => t('common:secondaryPhone'))
            .exhaustive();

          return {
            label: national,
            onClick: () => setSelectedContactAddress(phoneOption),
            subTitle: optionSubtitle,
            value: type,
            'data-lgg-id': `contact-phone-option-${type}`,
          };
        });
      },
      [t],
    );

    const tabBarExtraContent = useMemo(() => {
      return match(selectedTabKey)
        .with(ContactInteractionInputAreaTabKey.InternalNote, () => {
          if (breakpointUpMd && hasFocus) {
            return <InternalNotePrivacyMessage />;
          }

          return null;
        })
        .with(ContactInteractionInputAreaTabKey.Reply, () => {
          if (channelOptions) {
            return (
              <ReplyChannelSelector
                selectedChannel={selectedChannelSlug}
                setSelectedChannel={setSelectedChannelSlug}
                replyChannelOptions={channelOptions}
              />
            );
          }

          return null;
        })
        .exhaustive();
    }, [breakpointUpMd, channelOptions, hasFocus, selectedChannelSlug, selectedTabKey]);

    const hasAvailabilityForSelectedChannel = useMemo(() => {
      return match(selectedChannelSlug)
        .with(
          P.union('WHATSAPP', 'INSTAGRAM'),
          () => hasChannelsAvailability && isSelectedChannelContactWindowOpen,
        )
        .otherwise(() => true);
    }, [
      hasChannelsAvailability,
      isSelectedChannelContactWindowOpen,
      selectedChannelSlug,
    ]);

    const displayAttachmentsPlugin = useMemo(() => {
      if (!isReplyTabSelected || !isAttachmentsFeatureEnabled) return false;

      return match(selectedChannelSlug)
        .with('SMS', () => {
          return isSmsAndFacebookMessengerAttachmentsFeatureEnabled;
        })
        .with(null, () => false)
        .otherwise(() => true);
    }, [
      isAttachmentsFeatureEnabled,
      isReplyTabSelected,
      isSmsAndFacebookMessengerAttachmentsFeatureEnabled,
      selectedChannelSlug,
    ]);

    const getPhoneResourceSelectorBar = useCallback(
      ({ testIdPrefix }: { testIdPrefix: string }) => {
        const resources = channelAvailabilityForContact?.resources ?? [];

        return selectedResource && resources.length > 0 ? (
          <ResourceSelectorBar
            label={t('common:from')}
            selectedResource={selectedResource}
            options={mapChannelResourcesToOptions({
              resources,
              testIdGenerator: (id) => `${testIdPrefix}-option-${id}`,
            })}
            data-lgg-id={`${testIdPrefix}-selector-dropdown-button`}
          />
        ) : null;
      },
      [channelAvailabilityForContact?.resources, selectedResource, t],
    );

    const getSmsResourceSelectorBar = useCallback(
      ({ testIdPrefix }: { testIdPrefix: string }) => {
        const resources = channelAvailabilityForContact?.resources ?? [];

        return selectedResource && resources.length > 0 ? (
          <ResourceSelectorBar
            label={t('common:from')}
            selectedResource={selectedResource}
            options={(() => {
              const testIdGenerator = (id) => `${testIdPrefix}-option-${id}`;

              if (
                !getFeatureFlag(
                  'temp_lggdev-2163_sms-resources-grouped-by-linked-to-campaign',
                )
              ) {
                return mapChannelResourcesToOptions({ resources, testIdGenerator });
              }

              const groupedOptions = mapSmsResourcesToGroupedOptions({
                resources,
                testIdGenerator,
              });

              return {
                [t('smsChannel:campaignRegistry.a2pVerified')]: groupedOptions.linked,
                [t('smsChannel:campaignRegistry.notVerified')]: groupedOptions.notLinked,
              };
            })()}
            data-lgg-id={`${testIdPrefix}-selector-dropdown-button`}
          />
        ) : null;
      },
      [channelAvailabilityForContact?.resources, getFeatureFlag, selectedResource, t],
    );

    const getContactPhoneSelectorBar = useCallback(() => {
      return selectedContactAddress && contactPhoneNumbers.length > 0 ? (
        <ResourceSelectorBar
          label={t('common:to')}
          selectedResource={selectedContactAddress}
          options={mapContactAddressesToOptions({
            phoneOptions: contactPhoneNumbers,
          })}
          data-lgg-id="contact-phone-selector-dropdown-button"
        />
      ) : null;
    }, [contactPhoneNumbers, mapContactAddressesToOptions, selectedContactAddress, t]);

    const topContent = useMemo(() => {
      if (isInternalNoteTabSelected) {
        return null;
      }

      return match(selectedChannelSlug)
        .with('WHATSAPP', () => {
          // Only show the resource selector when the input has focus or
          // when the whatsapp window is closed, so we can save some space but letting
          // change resource when the window is closed
          const showResourceSelector = hasFocus || !hasAvailabilityForSelectedChannel;

          return (
            <FlexColumn>
              {showResourceSelector ? (
                <>
                  {getPhoneResourceSelectorBar({
                    testIdPrefix: 'whatsapp-resource',
                  })}
                  {getContactPhoneSelectorBar()}
                </>
              ) : null}

              {selectedResource && selectedContactAddress && (
                <WhatsappMessageClosedWindowContent
                  handleFetchCreatedItem={handleFetchCreatedItem}
                  resourceId={selectedResource?.id}
                  resourcePhoneNumber={selectedResourcePhoneNumber}
                  contactPhoneNumber={selectedContactAddress.e164}
                  conversationId={conversationId}
                  isWindowOpen={isSelectedChannelContactWindowOpen}
                />
              )}
            </FlexColumn>
          );
        })
        .with('INSTAGRAM', () => {
          if (hasAvailabilityForSelectedChannel) {
            return null;
          }

          return (
            <ContactInteractionsReplyTabInfoMessage
              title={t(
                'conversations:messageInput.tabs.reply.messages.instagram.messageWindowClosed.title',
              )}
              body={t(
                'conversations:messageInput.tabs.reply.messages.instagram.messageWindowClosed.body',
              )}
              lggId="instagram-message-window-closed-info-message"
            />
          );
        })
        .with('SMS', () => {
          return hasFocus ||
            isSmsResourceNotLinked ||
            isSmsResourceLimitReached ||
            isSmsResourceOptOut ? (
            <FlexColumn>
              {getSmsResourceSelectorBar({
                testIdPrefix: 'sms-resource',
              })}
              {getContactPhoneSelectorBar()}
              {isSmsResourceNotLinked ? <SmsResourceNotLinkedBanner /> : null}
              {isSmsResourceLimitReached ? <SmsLimitReachedBanner /> : null}
              {isSmsResourceOptOut ? <SmsOptOutBanner /> : null}
            </FlexColumn>
          ) : null;
        })
        .otherwise(() => null);
    }, [
      isSmsResourceOptOut,
      isInternalNoteTabSelected,
      selectedChannelSlug,
      hasFocus,
      hasAvailabilityForSelectedChannel,
      getPhoneResourceSelectorBar,
      getContactPhoneSelectorBar,
      selectedResource,
      selectedContactAddress,
      handleFetchCreatedItem,
      selectedResourcePhoneNumber,
      conversationId,
      isSelectedChannelContactWindowOpen,
      t,
      isSmsResourceNotLinked,
      isSmsResourceLimitReached,
      getSmsResourceSelectorBar,
    ]);

    if (!channelOptions) {
      return null;
    }

    const inputAreaMaxHeight =
      (Capacitor.getPlatform() === 'ios' || isIOS) && isFullHeight
        ? // 40px = 5px modal offset + 35px message type selector
          `${visualViewportHeight - 40 - (insets?.top ?? 0)}px`
        : '100%';

    return (
      <InputAreaContainer
        className={c({
          'full-height': isFullHeight,
        })}
        backgroundColor={getBackgroundColorBySelectedTabKey(selectedTabKey)}
      >
        {selectedChannelWindowAvailabilityMessage}
        <MessageTypeSelector
          selectedTabKey={ContactInteractionInputAreaTabKey[selectedTabKey]}
          onSelected={(selectedTabKey) => {
            setSelectedTabKey(selectedTabKey);
            setHasFocus(false);
          }}
          extraContent={tabBarExtraContent}
        />
        <InputAreaContent maxHeight={inputAreaMaxHeight}>
          {contact.isBlocked && isReplyTabSelected ? (
            <BlockedContactBanner contact={contact} />
          ) : hideContent ? null : (
            <>
              {!hasChannelsAvailability ? (
                <ContactInteractionsReplyTabInfoMessage
                  lggId="contact-interaction-unavailable-channels-info-message"
                  title={t(
                    'conversations:messageInput.tabs.reply.messages.general.unavailableChannels.title',
                  )}
                  body={
                    <FlexColumn>
                      {t(
                        'conversations:messageInput.tabs.reply.messages.general.unavailableChannels.body',
                      )}
                      <EditContactDetailDescription
                        data-lgg-id="unavailable-channels-info-message-edit-contact-option"
                        onClick={() => {
                          openRemoteModalWrapper(getLegacyContactEditUrl(contactId));
                        }}
                      >
                        {t(
                          'conversations:messageInput.tabs.reply.messages.general.unavailableChannels.editContactDetails',
                        )}
                      </EditContactDetailDescription>
                    </FlexColumn>
                  }
                />
              ) : null}
              <LexicalEditor
                selectedChannel={selectedChannelSlug ?? null}
                setHasFocus={setHasFocus}
                setSelectedTabKey={setSelectedTabKey}
                selectedInteraction={selectedInteraction}
                pluginConfig={{
                  emojiPicker: true,
                  messageAttachments: displayAttachmentsPlugin,
                  mentions: isMentionsFeatureEnabled && isInternalNoteTabSelected,
                  aiTools: isAiToolsFeatureEnabled && isReplyTabSelected,
                }}
                isInputEnabled={isInputEnabled}
                isInputActive={isInputActive && !isSendingReplyOrNote}
                handleSendMessage={handleSendMessage}
                hasFocus={hasFocus || (isReplyTabSelected && hasValidAttachments)}
                isFullHeight={isFullHeight}
                bottomContent={{
                  trailing: resolveLeftRailExtraContent(channelOptions),
                  visible: !hideBottomArea,
                  leading: null,
                }}
                topContent={topContent}
                onChange={(editorState) => {
                  editorState.read(() => {
                    const root = $getRoot();

                    setMessageValues({
                      message: root.getTextContent(),
                      rawMessage: JSON.stringify(editorState.toJSON()),
                    });
                  });
                }}
                selectedTab={selectedTabKey}
                onClear={(editor) => {
                  if (messageValues.message || attachmentList.length > 0) {
                    editor.update(
                      () => {
                        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
                      },
                      {
                        onUpdate: () => {
                          editor.blur();
                          clearMessageInputStates();
                        },
                      },
                    );
                  }
                }}
                placeholder={`${getPlaceholderTextByTabKey(selectedTabKey)}...`}
                onFocus={() => setHasFocus(true)}
                attachmentList={attachmentList}
                setAttachmentList={setAttachmentList}
                rightRailLeadingContent={
                  hasFocus && isReplyTabSelected ? (
                    <RightRailComplementElement
                      message={messageValues.message}
                      selectedChannelSlug={selectedChannelSlug}
                      attachmentCount={attachmentList.length}
                    />
                  ) : null
                }
              />
            </>
          )}
        </InputAreaContent>
      </InputAreaContainer>
    );
  },
);
