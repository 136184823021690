import React, {
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { VirtuosoHandle } from 'react-virtuoso';
import { useLazyQuery } from '@apollo/client';
import { get } from 'lodash';
import { up } from 'styled-breakpoints';
import styled, { DefaultTheme, createGlobalStyle } from 'styled-components';
import {
  ContactInteractionUnknown,
  ConversationItemsArgs,
  ConversationItemsWhereInput,
  ConversationItemUnion,
  QueryConversationArgs,
  Conversation,
  ContactInteractionDirection,
  ContactTimelineUnion,
} from '@lgg/isomorphic/types/__generated__/graphql';
import { matchContactInteractionExhaustive } from '@lgg/isomorphic/utils/match-contact-interaction';
import ChatBg from 'src/assets/images/conversations/bg-pattern.svg?url';
import NewChatIllustration from 'src/assets/images/conversations/new_chat_illustration.svg?url';
import { ConversationOptionsBar } from 'src/components/domain/contacts/contact-modal/contact-modal.shared';
import { useErrorHandling } from 'src/components/error-boundary/error-boundary';
import { InvertedVirtuoso } from 'src/components/general/display/inverted-virtuoso';
import { EmptyListPlaceholder } from 'src/components/general/feedback/empty-list-placeholder';
import { ListLoadingItem } from 'src/components/general/feedback/list-loading-item';
import { NewItemBubble } from 'src/components/general/feedback/new-item-bubble';
import { Skeleton } from 'src/components/general/feedback/skeleton';
import { FlexColumn } from 'src/components/layout/flex-column';
import { ClosedConversationBanner } from 'src/components/pages/conversations/components/contact-interactions/banners/closed-conversation-banner';
import { SnoozedConversationBanner } from 'src/components/pages/conversations/components/contact-interactions/banners/snoozed-conversation-banner';
import {
  CONVERSATION_FOR_CONTACT_INTERACTION_LIST,
  CONVERSATION_WITH_LAST_INTERACTION,
} from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/contact-interactions-list.query';
import { useContactInteractionMessageSenderGroups } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/hooks/get-contact-interaction-message-sender-groups';
import { getContactInteractionCreationDate } from 'src/components/pages/conversations/components/contact-interactions/contact-interaction-list/shared';
import { ContactInteractionsInputArea } from 'src/components/pages/conversations/components/contact-interactions/contact-interactions-input-area/contact-interactions-input-area';
import { ContactInteractionUnknownConversationItem } from 'src/components/pages/conversations/components/contact-interactions/items/contact-interaction-unknown-conversation-item';
import { ContactTimelineItem } from 'src/components/pages/conversations/components/contact-interactions/items/contact-timeline-item';
import { EmailItem } from 'src/components/pages/conversations/components/contact-interactions/items/email-item';
import { FacebookAdFormItem } from 'src/components/pages/conversations/components/contact-interactions/items/facebook-ad-form-item';
import { FacebookMessengerItem } from 'src/components/pages/conversations/components/contact-interactions/items/facebook-messenger-item';
import { InPersonItem } from 'src/components/pages/conversations/components/contact-interactions/items/in-person-item';
import { InstagramItem } from 'src/components/pages/conversations/components/contact-interactions/items/instagram-item';
import { NoteItem } from 'src/components/pages/conversations/components/contact-interactions/items/note-item';
import { PhoneCallItem } from 'src/components/pages/conversations/components/contact-interactions/items/phone-call-item';
import { SmsItem } from 'src/components/pages/conversations/components/contact-interactions/items/sms-item';
import { WhatsappItem } from 'src/components/pages/conversations/components/contact-interactions/items/whatsapp/whatsapp-item';
import { ContactInteractionsListContext } from 'src/components/pages/conversations/components/contact-interactions/shared';
import { useConversationUnsnooze } from 'src/components/pages/conversations/hooks/use-conversation-unsnooze';
import { useAuthorization } from 'src/hooks/use-authorization';
import { useDateHelpers } from 'src/hooks/use-date-helpers';
import { useFormatDate } from 'src/hooks/use-format-date';
import { useHandleGraphQLError } from 'src/hooks/use-handle-graphql-error';
import { useInfiniteListQuery } from 'src/hooks/use-infinite-list-query';
import { overlayShapeStyles } from 'src/theme/sub-themes/overlays-theme';
import { NotFoundError } from 'src/utils/errors/not-found-error';
import { publishEvent } from 'src/utils/events/pub-sub';
import { getNodesFromConnection } from 'src/utils/graphql/get-nodes-from-connection';

const ContextMenuStyle = createGlobalStyle<{ theme: DefaultTheme }>`
  .ant-dropdown.context-menu {
    ${overlayShapeStyles};
    padding: 5px;

    &.contact-actions-dropdown {
      padding: 0;
    }
  }
`;

const ContactInteractionsListSkeletonContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
`;

const SkeletonMainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${ChatBg});

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.alabaster};
  }
`;

const ContactInteractionsListSkeletonInputAreaContainer = styled.div`
  height: 100px;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.colors.white};
  border-top: 1px solid ${({ theme }) => theme.colors.koala};
`;

const ContactInteractionsListSkeletonInputArea = memo(() => (
  <ContactInteractionsListSkeletonInputAreaContainer>
    <Skeleton title={{ width: '150px' }} paragraph={{ rows: 1, width: '100%' }} active />
  </ContactInteractionsListSkeletonInputAreaContainer>
));

const ContactInteractionsListSkeletonItem = styled(Skeleton)<{
  direction: ContactInteractionDirection;
}>`
  padding: 10px 16px;

  .ant-skeleton-content {
    align-items: ${({ direction }) => (direction === 'INBOUND' ? 'start' : 'end')};
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    .ant-skeleton-title {
      border-radius: 15px;
      margin: 0 0 10px;
    }

    .ant-skeleton-paragraph {
      display: flex;
      justify-content: ${({ direction }) => (direction === 'INBOUND' ? 'start' : 'end')};
      margin: 0;
      width: 100%;
    }

    .ant-skeleton-paragraph > li {
      height: 78px;
    }
  }
`;

type ContactInteractionSkeletonProps = PropsWithChildren<{
  direction: ContactInteractionDirection;
}>;

const ContactInteractionSkeleton = memo<ContactInteractionSkeletonProps>(
  ({ direction }) => (
    <ContactInteractionsListSkeletonItem
      title={{ width: '180px' }}
      direction={direction}
      paragraph={{ rows: 1, width: '71%' }}
      active
    />
  ),
);

const ContactInteractionsListSkeleton = memo(() => (
  <SkeletonMainContainer>
    <ContactInteractionsListSkeletonContainer>
      <ContactInteractionSkeleton direction="INBOUND" />
      <ContactInteractionSkeleton direction="OUTBOUND" />
      <ContactInteractionsListSkeletonInputArea />
    </ContactInteractionsListSkeletonContainer>
  </SkeletonMainContainer>
));

const StyledContactInteractionsList = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  background-image: url(${ChatBg});
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  ${up('md')} {
    background-color: ${({ theme }) => theme.colors.alabaster};
  }
`;

const InvertedEmptyListPlaceholder = styled(EmptyListPlaceholder)`
  margin-top: 70px;
  transform: scaleY(-1);
`;

const ContactInteractionsListAndInputContainer = styled(FlexColumn)`
  justify-content: space-between;
  height: 100%;
`;

const CONTACT_INTERACTION_PAGE_SIZE = 30;

const getCIMetadataFromParam = (param: string | null | undefined) => {
  if (!param) {
    return {
      contactInteractionType: null,
      contactInteractionId: null,
    };
  }

  const matches = param.match(/(note)-(\S+)/);

  if (matches) {
    return {
      contactInteractionType: matches[1],
      contactInteractionId: matches[2],
    };
  }

  return {
    contactInteractionType: 'ci',
    contactInteractionId: param,
  };
};

const isContactTimeline = (
  interaction: ConversationItemUnion,
): interaction is Extract<ConversationItemUnion, ContactTimelineUnion> =>
  interaction.__typename.startsWith('ContactTimeline');

type ContactInteractionsListProps = {
  conversationId: string;
  contactInteractionIdParam?: string | null;
  onLoadConversation?: ValueChanged<Conversation>;
  showOptionsBanner?: boolean;
};

export const ContactInteractionsList = memo<ContactInteractionsListProps>(
  ({
    conversationId,
    onLoadConversation,
    contactInteractionIdParam,
    showOptionsBanner = false,
  }) => {
    const { triggerError } = useErrorHandling();
    const handleGraphQLError = useHandleGraphQLError();
    const lastContactInteractionDate = useRef<string>();
    const [fetchConversationWithLastInteraction] = useLazyQuery(
      CONVERSATION_WITH_LAST_INTERACTION,
    );
    const [isFirstLoadMore, setIsFirstLoadMore] = useState(true);
    const [isInputSectionRendered, setIsInputSectionRendered] = useState(false);
    const { contactInteractionType, contactInteractionId } = getCIMetadataFromParam(
      contactInteractionIdParam,
    );

    const [fetchingSpecificInteraction, setFetchingSpecificInteraction] = useState(
      Boolean(contactInteractionId),
    );
    const wasItemHighlighted = useRef(false);
    const initialFetch = useRef(true);
    const scrollListToBottomRef = useRef<VoidFunction | null>(null);
    const virtuosoHandleRef = useRef<VirtuosoHandle | null>(null);
    const { formatRelativeGroupDate } = useFormatDate();
    const { unsnoozeConversation, isDiscardingSnooze } = useConversationUnsnooze();
    const { t } = useTranslation(['conversations']);
    const { ensureDate } = useDateHelpers();
    const baseVariables = useMemo(
      () => ({ id: conversationId, last: CONTACT_INTERACTION_PAGE_SIZE }),
      [conversationId],
    );
    const { getFeatureFlag } = useAuthorization();
    const isMentionsFeatureEnabled = getFeatureFlag(
      'temp_lggdev-933_agregar-feature-flag-para-los-mention-en-internal-notes-y-los-attachments',
    );

    const variables = useMemo(() => {
      if (contactInteractionId) {
        initialFetch.current = true;
        setFetchingSpecificInteraction(true);

        return {
          ...baseVariables,
          where:
            contactInteractionType === 'note'
              ? { conversationNoteId: { eq: contactInteractionId } }
              : { contactInteractionId: { eq: parseInt(contactInteractionId) } },
        };
      }

      return baseVariables;
    }, [contactInteractionId, baseVariables, contactInteractionType]);

    const {
      nodes,
      firstItemIndex,
      clearHasNewItem,
      loading,
      hasNewItem,
      data,
      handleLoadTop,
      fetchMore,
      handleLoadBottom,
      loadingMoreBottom,
      loadingMoreTop,
      setHasNewItem,
      error,
      refetch,
    } = useInfiniteListQuery<
      { conversation: Conversation },
      QueryConversationArgs & Partial<ConversationItemsArgs>,
      ConversationItemUnion
    >({
      queryOptions: {
        query: CONVERSATION_FOR_CONTACT_INTERACTION_LIST,
        variables,
        isReversedList: true,
        sortNodesCallback: (nodes) => nodes.reverse(),
        loadMoreVariables: { ...variables, where: null },
        getNodeIdCallback: (contactInteraction) => contactInteraction?.id,
        getEdgesCallback: (data) => data?.conversation?.items?.edges ?? [],
        getPageInfoCallback: (data) => data?.conversation?.items?.pageInfo,
        queryPageSize: CONTACT_INTERACTION_PAGE_SIZE,
        fetchPolicy: 'network-only',
        onError: (error) => handleGraphQLError(error, { isNavigation: true }),
        onCompleted: (data) => {
          const { conversation } = data;
          const {
            items: { edges },
            lastContactInteraction,
          } = conversation;

          if (initialFetch.current) {
            lastContactInteractionDate.current = lastContactInteraction?.occurredAt;
            initialFetch.current = false;

            if (fetchingSpecificInteraction) {
              const requestedRecord = edges.find(
                (edge) => edge.node.id === contactInteractionId,
              );

              if (!requestedRecord) {
                triggerError(new NotFoundError(), { isNavigation: true });
                return;
              }

              const fetchPages = async () => {
                await fetchMore({
                  variables: {
                    last: CONTACT_INTERACTION_PAGE_SIZE,
                    before: requestedRecord.cursor,
                    after: null,
                    first: null,
                    where: null,
                  },
                });

                await fetchMore({
                  variables: {
                    first: CONTACT_INTERACTION_PAGE_SIZE,
                    after: requestedRecord.cursor,
                    before: null,
                    last: null,
                    where: null,
                  },
                });

                setFetchingSpecificInteraction(false);
              };

              void fetchPages();
            }
          }

          if (onLoadConversation) {
            onLoadConversation(conversation);
          }
        },
      },
      pollingOptions: {
        interval: 10000,
        pollingDirection: 'BOTTOM',
        beforePolling: async (data) => {
          const hasNextPage = data.conversation.items.pageInfo.hasNextPage;

          const fetchLatestContactInteraction = async () => {
            // Fetch the last contact interaction from the conversation
            const result = await fetchConversationWithLastInteraction({
              variables: { id: conversationId },
            });

            const fetchedLastContactInteraction =
              result.data?.conversation?.lastContactInteraction;

            const isExistingInteraction = nodes.find(
              (node) => node.id === fetchedLastContactInteraction?.id,
            );

            // If there is no contact interaction on the conversation or
            // the new last contact interaction is already in the list, we don't want to show the new item bubble
            if (!fetchedLastContactInteraction || Boolean(isExistingInteraction)) {
              return;
            }

            // If the last contact interaction date is the same as the last one we fetched,
            // we don't want to show the new item bubble
            if (
              fetchedLastContactInteraction.occurredAt ===
              lastContactInteractionDate.current
            ) {
              return;
            }

            // If the query has a next page meaning that we haven't reached the end of the list,
            // we want to show the new item bubble
            if (hasNextPage) {
              setHasNewItem(true);
            } else {
              // If we have loaded all the available pages, we need to check if the scroller is scrolled,
              // to avoid showing the new item bubble when the user is at the bottom of the list
              const scrollerElement = virtuosoScrollerRef.current as HTMLDivElement;

              if (!scrollerElement) {
                return;
              }

              const scrollerIsScrolled =
                Math.abs(
                  scrollerElement.scrollHeight -
                    scrollerElement.clientHeight -
                    scrollerElement.scrollTop,
                ) >= 1;

              // We have a new item and the user is not at the bottom of the list,
              // so we show the new item bubble
              if (scrollerIsScrolled) {
                setHasNewItem(true);
              }
            }

            // Update the last contact interaction date for next polling
            lastContactInteractionDate.current = fetchedLastContactInteraction.occurredAt;
          };

          void fetchLatestContactInteraction();

          return !hasNextPage;
        },
        onPollingResult: () => {
          publishEvent('CONVERSATION_UPDATED', { id: conversationId });
        },
      },
    });

    const selectedInteraction = useMemo(() => {
      if (!contactInteractionId) {
        return undefined;
      }

      return nodes.find((node) => node.id === contactInteractionId);
    }, [contactInteractionId, nodes]);

    const virtuosoScrollerRef = useRef<HTMLElement | Window | null>(null);

    useEffect(() => {
      initialFetch.current = true;
    }, [conversationId]);

    const messageGroups = useContactInteractionMessageSenderGroups({
      contactInteractions: nodes,
      contactLabel: get(data, 'conversation.contact.label') ?? '',
    });

    const handleNewItemBubbleClick = useCallback(async () => {
      clearHasNewItem();

      if (!data) {
        return;
      }

      if (data.conversation.items.pageInfo.hasNextPage) {
        await refetch({ ...baseVariables, where: null });
      }

      scrollListToBottomRef.current?.();
    }, [baseVariables, clearHasNewItem, data, refetch]);

    if ((loading && !data) || fetchingSpecificInteraction)
      return <ContactInteractionsListSkeleton />;
    if (!data) return <div>no data</div>;
    if (error) return <div>error</div>;

    const { conversation } = data;
    const {
      isOpen,
      isSnoozed,
      snoozedUntil,
      contact: { primaryEmail: contactEmail },
    } = conversation;
    const initialTopMostItemIndex = contactInteractionId
      ? nodes.findIndex((ci) => ci.id === contactInteractionId)
      : 0;

    const handleFetchCreatedItem = async (where: ConversationItemsWhereInput) => {
      const createdItemResult = await fetchMore({
        variables: {
          where,
        },
      });

      if (createdItemResult && 'data' in createdItemResult) {
        const nodeList = getNodesFromConnection(
          createdItemResult.data.conversation.items,
        );
        const [createdNode] = nodeList;
        const messageDirection =
          'direction' in createdNode ? createdNode.direction : null;

        if (
          createdNode &&
          createdNode.__typename !== 'ConversationNote' &&
          createdNode.__typename !== 'ContactNote' &&
          messageDirection !== 'OUTBOUND'
        ) {
          lastContactInteractionDate.current = createdNode.occurredAt;
        }
      }

      setTimeout(() => {
        scrollListToBottomRef.current?.();
      }, 100);
    };

    return (
      <ContactInteractionsListContext.Provider
        value={{
          conversationId,
          contactInteractions: nodes,
          virtuosoHandle: virtuosoHandleRef.current,
          virtuosoScroller: virtuosoScrollerRef.current,
        }}
      >
        {showOptionsBanner && <ConversationOptionsBar conversation={conversation} />}
        <ContactInteractionsListAndInputContainer>
          <StyledContactInteractionsList data-lgg-id="contact-interactions-list">
            {isInputSectionRendered ? (
              <>
                {!isOpen && <ClosedConversationBanner />}
                {isSnoozed && snoozedUntil && (
                  <SnoozedConversationBanner
                    snoozedUntil={ensureDate(snoozedUntil)}
                    loading={isDiscardingSnooze}
                    discardSnoozeHandler={async () => {
                      await unsnoozeConversation({
                        variables: {
                          conversationId: conversationId,
                        },
                      });
                    }}
                  />
                )}
                <InvertedVirtuoso
                  key={conversationId}
                  virtuosoRef={(ref) => {
                    virtuosoHandleRef.current = ref;
                  }}
                  scrollerRef={(ref) => (virtuosoScrollerRef.current = ref)}
                  firstItemIndex={firstItemIndex}
                  scrollToBottomRef={(ref) => (scrollListToBottomRef.current = ref)}
                  initialTopMostItemIndex={initialTopMostItemIndex}
                  increaseViewportBy={1000}
                  startReached={async () => {
                    await handleLoadTop();
                    setTimeout(() => {
                      setIsFirstLoadMore(false);
                    }, 1000);
                  }}
                  endReached={async () => {
                    await handleLoadBottom();
                  }}
                  data={nodes}
                  components={{
                    Footer: () => {
                      if (loadingMoreBottom) {
                        return (
                          <ListLoadingItem
                            data-lgg-id="contact-interactions-list-loading-more"
                            visible={loadingMoreBottom}
                          />
                        );
                      }

                      return null;
                    },
                    Header: () => {
                      const padding = <div style={{ minHeight: '5px' }} />;

                      if (isFirstLoadMore) {
                        return padding;
                      } else if (!loadingMoreTop) {
                        return padding;
                      }

                      return (
                        <div>
                          {padding}
                          <ListLoadingItem
                            data-lgg-id="contact-interactions-list-loading-more"
                            visible={loadingMoreTop}
                          />
                        </div>
                      );
                    },
                    EmptyPlaceholder: () => (
                      <InvertedEmptyListPlaceholder
                        data-lgg-id="contact-interactions-empty-list"
                        iconConfig={{ type: 'inline', icon: NewChatIllustration }}
                        title={t('conversations:interactionsList.emptyState.title')}
                        message={t(
                          'conversations:interactionsList.emptyState.description',
                        )}
                      />
                    ),
                  }}
                  atTopStateChange={(atBottom) => {
                    if (hasNewItem && atBottom) {
                      setHasNewItem(false);
                    }
                  }}
                  itemsRendered={() => {
                    if (
                      !wasItemHighlighted.current &&
                      virtuosoScrollerRef.current &&
                      Boolean(contactInteractionId)
                    ) {
                      const virtuosoScroller =
                        virtuosoScrollerRef.current as HTMLDivElement;

                      const findItem = (selector: string) => {
                        return virtuosoScroller.querySelector(
                          selector,
                        ) as HTMLDivElement | null;
                      };

                      const highLightItem = (element: HTMLDivElement) => {
                        element.classList.add(
                          contactInteractionType === 'note'
                            ? 'note-highlighted'
                            : 'ci-highlighted',
                        );
                        element.addEventListener('animationend', () => {
                          wasItemHighlighted.current = true;
                        });
                      };

                      const contactInteractionToHighLight = findItem(
                        `[data-id="${contactInteractionId}"]`,
                      );

                      if (contactInteractionToHighLight) {
                        highLightItem(contactInteractionToHighLight);
                        return;
                      }

                      if (!contactInteractionToHighLight) {
                        const attachmentToHighlight = findItem(
                          `[data-id-ci="attachment-for-${contactInteractionId}"]`,
                        );

                        if (!attachmentToHighlight) {
                          return;
                        }

                        highLightItem(attachmentToHighlight);
                      }
                    }
                  }}
                  itemContent={(index) => {
                    const currentContactInteraction = nodes[index];
                    const messageGroup = messageGroups[currentContactInteraction.id];

                    const ciItemResolver = () => {
                      if (
                        currentContactInteraction.__typename === 'ConversationNote' ||
                        currentContactInteraction.__typename === 'ContactNote'
                      ) {
                        return (
                          <NoteItem
                            internalNote={currentContactInteraction}
                            conversationId={conversationId}
                            isMentionsFeatureEnabled={
                              currentContactInteraction.__typename === 'ConversationNote'
                                ? isMentionsFeatureEnabled
                                : false
                            }
                          />
                        );
                      }

                      if (isContactTimeline(currentContactInteraction)) {
                        return (
                          <ContactTimelineItem
                            contactTimeline={currentContactInteraction}
                            contact={conversation.contact}
                          />
                        );
                      }

                      return matchContactInteractionExhaustive(
                        currentContactInteraction,
                        {
                          unknown: (contactInteraction) => (
                            <ContactInteractionUnknownConversationItem
                              contactInteractionLogId={
                                contactInteraction.__typename ===
                                'ContactInteractionWhatsapp'
                                  ? contactInteraction.message?.id
                                  : undefined
                              }
                              contactInteraction={
                                contactInteraction as unknown as ContactInteractionUnknown
                              }
                              conversationId={conversationId}
                            />
                          ),
                          inPerson: (contactInteraction) => {
                            return (
                              <InPersonItem
                                conversationId={conversationId}
                                contactInteraction={contactInteraction}
                              />
                            );
                          },
                          facebookMessenger: (contactInteraction) => (
                            <FacebookMessengerItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                            />
                          ),
                          instagram: (contactInteraction) => (
                            <InstagramItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                            />
                          ),
                          facebookAd: (contactInteraction) => (
                            <FacebookAdFormItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                            />
                          ),
                          email: (contactInteraction) => (
                            <EmailItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                              contactEmail={contactEmail}
                            />
                          ),
                          sms: (contactInteraction) => (
                            <SmsItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                            />
                          ),
                          whatsapp: (contactInteraction) => (
                            <WhatsappItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                            />
                          ),
                          phone: (contactInteraction) => (
                            <PhoneCallItem
                              conversationId={conversationId}
                              contactInteraction={contactInteraction}
                            />
                          ),
                        },
                      );
                    };

                    return (
                      <>
                        {messageGroup}
                        <div style={{ minHeight: '5px' }} />
                        {ciItemResolver()}
                      </>
                    );
                  }}
                  getItemId={(ci) => ci.id}
                  groupResolver={(ci) =>
                    formatRelativeGroupDate(getContactInteractionCreationDate(ci))
                  }
                />
                {hasNewItem && (
                  <NewItemBubble
                    text={t('conversations:newItemsBubble.newInteractions')}
                    direction="BOTTOM"
                    onClick={handleNewItemBubbleClick}
                  />
                )}
              </>
            ) : (
              <ContactInteractionsListSkeleton />
            )}
          </StyledContactInteractionsList>
          <ContactInteractionsInputArea
            key={conversationId}
            handleFetchCreatedItem={handleFetchCreatedItem}
            contact={conversation.contact}
            conversationId={conversationId}
            setIsRendered={setIsInputSectionRendered}
            selectedInteraction={selectedInteraction}
          />
          <ContextMenuStyle />
        </ContactInteractionsListAndInputContainer>
      </ContactInteractionsListContext.Provider>
    );
  },
);
